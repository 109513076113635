import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { loadFeatureTranslation, withFeatureTranslation } from '@lf/translate-core';
import { MenuPermissionsPipe } from '@permissions/menu-permissions/menu-permissions.pipe';
import { MenuItem } from '@type/menu.type';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { SubmenuDirective } from '../submenu/submenu.directive';
import { UserSpaceSubmenuComponent } from '../user-space-submenu/user-space-submenu.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [RouterModule, MenuItemComponent, SubmenuDirective, MenuPermissionsPipe],
  providers: [withFeatureTranslation({ fileName: 'menu' })],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@loadFeatureTranslation('menu')
export class MenuComponent {
  topMenu: MenuItem[] = [
    { name: '', url: '/', iconName: 'logo' },
    { name: 'dashboard', url: 'dashboard', iconName: 'dashboard' },
    {
      name: 'matrix',
      url: '',
      iconName: 'matrix',
      childUrls: [
        {
          name: 'assortment_matrix',
          url: '/matrix/assortment-matrix',
        },
        {
          name: 'matrix_history',
          url: '/matrix/history',
        },
      ],
    },
    {
      name: 'w_matrix',
      url: '',
      iconName: 'w-matrix',
      childUrls: [
        {
          name: 'warehouse_matrix',
          url: '/w-matrix',
        },
        {
          name: 'warehouse_matrix_history',
          url: '/w-matrix/history',
        },
      ],
    },
    { name: 'strategy', url: 'strategy', iconName: 'strategy' },
    { name: 'statistic', url: '/statistic/sku-statistic', iconName: 'statistic' },
    { name: 'classifier', url: 'classifier', iconName: 'classifier' },
    { name: 'clustering', url: 'clustering', iconName: 'clustering' },
    {
      name: 'log',
      url: '',
      iconName: 'log',
      childUrls: [
        {
          name: 'assortment_matrix',
          url: '/log/matrix',
        },
        {
          name: 'warehouse_matrix',
          url: '/log/warehouse',
        },
        {
          name: 'clusterization_log',
          url: '/log/clusterization',
        },
        {
          name: 'settings_log',
          url: '/log/settings',
        },
        {
          name: 'exchange_log',
          url: '/log/exchange',
        },
      ],
    },
  ];

  bottomMenu: MenuItem[] = [
    { name: 'settings', url: 'settings', iconName: 'settings' },
    {
      name: 'user',
      url: '',
      iconName: 'user',
      menuComponent: UserSpaceSubmenuComponent,
    },
  ];
}
