import { inject } from '@angular/core';
import { TranslateLoaderService, TranslateStoreService } from '../../public-api';

type FileName = string;

export function createTranslateResolverFn(fileName: FileName) {
  return async function translateResolver() {
    const tls = inject(TranslateLoaderService);
    const tss = inject(TranslateStoreService);

    const result = await tls.load(fileName);
    tss.set(fileName, result);

    return result;
  };
}
