import { OverlayRef } from '@angular/cdk/overlay';
import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@lf/translate-core';
import { LinkComponent } from '@lf/ui';
import { MENU_ITEM } from '@tokens/menu-item.token';

@Component({
  selector: 'app-submenu',
  standalone: true,
  imports: [UpperCasePipe, RouterModule, TranslatePipe, LinkComponent],
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
  menuItem = inject(MENU_ITEM);
  overlayRef = inject(OverlayRef);

  @HostListener('click')
  @HostListener('mouseleave')
  mouseleave(): void {
    this.overlayRef?.detach();
  }
}
