import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { loadFeatureTranslation, withFeatureTranslation } from '@lf/translate-core';
import { IconButtonComponent } from '@modules/components/icon-button/icon-button.component';
import { InsightService } from '@services/insight/insight.service';
import { CATEGORY } from '@tokens/category.token';
import { OVERLAY_REF, OVERLAY_TRIGGER } from '@tokens/overlay-ref.token';
import { createInjectorFn } from '@utils/create-provider';
import { combineLatest, filter, map, startWith, switchMap } from 'rxjs';
import { HeaderInsightsComponent } from '../header-insights/header-insights.component';

@Component({
  selector: 'app-insight',
  standalone: true,
  imports: [IconButtonComponent],
  templateUrl: './insight.component.html',
  styleUrl: './insight.component.scss',
  providers: [withFeatureTranslation({ fileName: 'insight' })],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@loadFeatureTranslation('insight')
export class InsightComponent {
  private category = inject(CATEGORY).asObservable;

  private insightService = inject(InsightService);

  private overlay: Overlay = inject(Overlay);

  private overlayRef: OverlayRef | undefined;

  private createInjector: ReturnType<typeof createInjectorFn> = createInjectorFn();

  #top = '54px';

  #right = '46px';

  activeInsightsButton = signal<boolean>(false);

  @ViewChild('showInsightsButton', { read: ElementRef<HTMLElement> })
  private showInsightsButton: ElementRef<HTMLElement> | undefined;

  counter = toSignal(
    combineLatest({
      category: this.category.pipe(filter((c) => !!c?.id)),
      trigger: this.insightService.updateInsightTrigger.pipe(startWith(true)),
    }).pipe(
      map(({ category }) => category),
      filter(Boolean),
      switchMap(({ id }) =>
        this.insightService
          .getUnreadCounter(id)
          .pipe(map(({ not_read_count }) => (not_read_count > 99 ? 99 : not_read_count))),
      ),
    ),
    { initialValue: 0 },
  );

  showInsights(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    } else {
      this.activeInsightsButton.set(true);
      this.overlayRef = this.overlay.create({
        width: 406,
        maxHeight: `calc(100vh - ${this.#top} * 2)`,
        positionStrategy: this.getPositionStrategy(),
        hasBackdrop: true,
        backdropClass: 'abm-backdrop-menu-class',
        disposeOnNavigation: true,
      });

      const componentPortal = new ComponentPortal(
        HeaderInsightsComponent,
        null,
        this.createInjector([
          { useValue: this.overlayRef, provide: OVERLAY_REF },
          { provide: OVERLAY_TRIGGER, useValue: this.showInsightsButton },
        ]),
      );

      this.overlayRef.attach(componentPortal);

      const subscription = this.overlayRef.detachments().subscribe(() => {
        this.setActiveStateForInsightsButton();
        subscription.unsubscribe();
      });
    }
  }

  private setActiveStateForInsightsButton(): void {
    this.activeInsightsButton.set(!!this.overlayRef?.hasAttached());
  }

  private getPositionStrategy() {
    return this.overlay.position().global().top(this.#top).right(this.#right);
  }
}
