<div class="page-tabs-container">
  @if (category()) {
    <div class="pinned-tabs">
      @for (tab of pinnedTabs(); track tab) {
        <app-pinned-tab
          #tab
          [isPinned]="tab.isPinned"
          [data]="tab.data"
          [name]="tab.name" />
      }
    </div>
  }
  <div
    class="tabs"
    #tabsContainer>
    @for (tab of observedTabs(); track tab) {
      <app-tab
        #tab
        (hideCategory)="removeCategoryFromObservedTabs(tab)"
        [name]="tab.name"
        [data]="tab.data" />
    }
  </div>
  <div class="add-tab">
    <app-add-tab
      #tab
      [abmMenuTriggerFor]="menu" />
  </div>
</div>

<ng-content select="[btn]"></ng-content>

<abm-menu #menu="menu">
  @if (showSearchField()) {
    <abm-search-field
      [withLabel]="false"
      [(ngModel)]="query"
      [placeholder]="'placeholder.search' | t" />
  }
  <div class="menu">
    @for (item of freeTabs(); track item) {
      <abm-menu-item (click)="addCategoryToObservedTabs(item)">{{ item.name }}</abm-menu-item>
    }
  </div>
</abm-menu>
