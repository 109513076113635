<aside class="menu">
  <app-menu></app-menu>
</aside>
<section
  id="fallback-spinner-container"
  class="main">
  @if (!(routeData | async)?.fullPage && user().id) {
    <app-header></app-header>
  }

  <router-outlet></router-outlet>
</section>

<div id="spinner-text-node">
  <p>{{ 'loading' | t }}</p>
</div>
