<div class="user-space">
  <header>
    <lf-link
      [link]="['user']"
      class="lf-link">
      <div class="link-content">
        @if (user(); as currentUser) {
          <div class="user-data">
            <div class="name">{{ currentUser.first_name + ' ' + currentUser.last_name }}</div>
            <div class="email">{{ currentUser.email }}</div>
          </div>
        }
        <abm-icon src="assets/icons/icon-chevron-right.svg"></abm-icon>
      </div>
    </lf-link>
  </header>
  <section class="links">
    <lf-link
      class="submenu-item lf-link"
      [link]="['manual']">
      <abm-icon
        class="link-icon"
        src="assets/icons/icon-info.svg" />
      <span class="text">{{ 'main_menu.profile.manual' | t }}</span>
    </lf-link>
    @if (false) {
      <div>
        <div class="link">
          <abm-icon
            class="link-icon"
            src="assets/icons/icon-info.svg"></abm-icon>
          <span class="text">{{ 'main_menu.profile.about_system' | t }}</span>
        </div>
        <div class="link">
          <abm-icon
            class="link-icon"
            src="assets/icons/icon-news.svg"></abm-icon>
          <span class="text">{{ 'main_menu.profile.news' | t }}</span>
        </div>
        <div class="link">
          <abm-icon
            class="link-icon"
            src="assets/icons/icon-support.svg"></abm-icon>
          <span class="text">{{ 'main_menu.profile.support' | t }}</span>
        </div>
      </div>
    }
    <div
      class="link"
      (click)="logout()">
      <abm-icon
        class="link-icon logout"
        src="assets/icons/icon-logout.svg"></abm-icon>
      <span class="text logout">{{ 'main_menu.profile.log_out' | t }}</span>
    </div>
  </section>
</div>
